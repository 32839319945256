<template>
  <view-container>
    <div class="py-6">
      <div class="flex justify-between items-center">
        <div>
          <h2 class="text-xl font-bold py-1 bg-gradient-to-r from-primary to-indigo-400 inline-block text-transparent bg-clip-text">
            {{ partOfDay }}, {{ fullName }}
          </h2>
          <p class="text-base-content/50 text-sm">{{ randomQuote }}</p>
        </div>
      </div>
    </div>

    <h3 class="mb-4 text-sm">Dôležité odkazy</h3>
    <div class="flex gap-2">
      <!-- <a href="https://kb.dajanarodriguez.app" class="btn btn-neutral">Knowledge base</a>-->
      <a href="https://dajanarodriguez.sk/admin" class="btn btn-neutral">Administrácia systému</a>
    </div>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Dashboard',
  data: () => ({
    motivationalQuotes: [
      'The only way to do great work is to love what you do.',
      'Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.',
      'The only limit to our realization of tomorrow will be our doubts of today.',
      'The only way to achieve the impossible is to believe it is possible.',
      'The difference between ordinary and extraordinary is that little extra.',
      'Don\'t watch the clock; do what it does. Keep going.',
      'Believe you can, and you\'re halfway there.',
      'Challenges are what make life interesting. Overcoming them is what makes life meaningful.',
      'The journey of a thousand miles begins with a single step.',
      'Don\'t be afraid to take a chance. Don\'t be afraid to make a mistake. Just don\'t be afraid to fail.',
      'Our doubts are traitors and make us lose the good we oft might win by fearing to attempt.',
      'Don\'t be afraid to dream seemingly impossible dreams.',
      'The journey of a thousand miles begins with a single step.',
      'It always seems impossible until it\'s done.',
      'Nothing is impossible.',
      'The mind is everything. What you think you become.',
      'The only way to achieve the impossible is to believe it is possible.',
      'The journey of a thousand miles begins with a single step.',
      'Every day is a new day.',
      'The only way to do great work is to love what you do.',
      'Every day is ending, but for every ending, there\'s a new beginning.'
    ]
  }),
  computed: {
    ...mapState({
      employee: state => state.employee.employeeData
    }),
    fullName() {
      if (this.employee) {
        return this.employee.fullName.split(' ')[0]
      }
      return ''
    },
    randomQuote() {
      const date = new Date()
      const day = date.getDate()
      return this.motivationalQuotes[day % this.motivationalQuotes.length]
    },
    partOfDay() {
      const date = new Date()
      const hours = date.getHours()
      if (hours < 12) {
        return 'Dobré ráno'
      } else if (hours >= 12 && hours <= 17) {
        return 'Dobrý deň'
      } else if (hours > 17 && hours <= 21) {
        return 'Dobrý večer'
      } else {
        return 'Dobrú noc'
      }
    }
  },
  mounted() {
    this.loading = 0
  }
}
</script>

<style scoped>

</style>
