import localStorage from '@/plugins/localStorage'

const state = {
  employeeData: localStorage.get('employeeData', true) || null,
  sessionToken: localStorage.get('sessionToken') || null,
  attendanceTypes: [],
}

const actions = {
  login({ commit }, payload) {
    commit('login', payload)
  },
  logout({ commit }) {
    commit('logout')
  },
  setAttendanceTypes({ commit }, payload) {
    commit('setAttendanceTypes', payload)
  },
}

const mutations = {
  login(state, payload) {
    state.employeeData = payload
    state.sessionToken = payload.session_token

    localStorage.set('employeeData', payload, true)
    localStorage.set('sessionToken', payload.session_token)
  },
  logout(state) {
    state.employeeData = state.sessionToken = null

    localStorage.remove('employeeData')
    localStorage.remove('sessionToken')
  },
  setAttendanceTypes(state, payload) {
    state.attendanceTypes = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
