<template>
  <view-container :loading="loading">
    <div class="flex justify-between mb-6">
      <h3 class="font-bold">Notifikácie</h3>
    </div>

    <div v-if="notifications.length">
      <div v-for="notification in notifications" :key="'nl'+notification.id" class="flex p-4 rounded-lg mb-4 gap-4"
           :class="{ 'bg-neutral-800': !notification.is_readed, 'bg-neutral-900': notification.is_readed }">
        <div>
          <svg v-if="notification.type.id === 2" xmlns="http://www.w3.org/2000/svg" class="stroke-warning shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
          </svg>
          <svg v-if="notification.type.id === 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info shrink-0 w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </div>
        <div>
          <div class="text-sm font-bold">
            {{ notification.name }}
          </div>
          <div class="py-2" v-html="notification.description"></div>
          <div class="flex gap-2">
            <div class="text-xs text-gray-500">{{ formatBefore(notification.updated_at) }}</div>
            <div class="text-gray-500 text-xs" v-if="notification.is_readed">Prečítané</div>
          </div>
          <div v-if="!notification.is_readed" class="mt-2">
            <button class="btn btn-sm" @click="readNotification(notification)">Označiť ako prečítané</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center text-gray-500 p-4">
      Žiadne notifikácie
    </div>
  </view-container>
</template>

<script>
import { request } from '@/api/request'
import dayjs from 'dayjs'

export default {
  name: 'Notifications',
  data: () => ({
    notifications: [],
    loading: 0
  }),
  methods: {
    async readNotification(notification) {
      await request('readNotification', {
        notificationId: notification.id
      })
      await this.fetchData()
    },
    formatBefore(date, locale = 'sk') {
      if (!date) {
        return
      }

      return dayjs(date).locale(locale).fromNow()
    },
    async fetchData() {
      const data = await request('getEmployeeNotifications')
      if (data) {
        this.notifications = data.notifications
      }
    }
  },
  async created() {
    this.loading = 1
    await this.fetchData()
    this.loading = 0
  }
}
</script>

<style scoped>

</style>
