<template>
  <dialog
    :open="show"
    :class="['modal', { 'modal-open': show }, position]"
  >
    <div :class="['modal-box', size]">
      <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" @click="close">✕</button>
      <slot></slot>
    </div>
  </dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    size: {
      type: String,
      default: 'w-11/12 max-w-5xl'
    },
    position: {
      type: String,
      default: 'items-start py-5'
    }
  },
  data: () => ({
    show: false
  }),
  methods: {
    close() {
      this.show = false
      document.body.classList.remove('modal-open')

      setTimeout(() => {
        this.$emit('closed')
      }, 200)
    },
    open() {
      this.show = true
      document.body.classList.add('modal-open')
    },
    closeOnEscape(e) {
      if (e.key === 'Escape') {
        this.close()
      }
    }
  },
  created() {
    document.addEventListener('keydown', this.closeOnEscape)
  },
  unmounted() {
    document.removeEventListener('keydown', this.closeOnEscape)
  }
}
</script>

<style lang="scss">
.modal {
  overflow-y: scroll;
  height: 100vh;
}
.modal-box {
  overflow-y: unset;
  max-height: unset;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  @apply bg-black bg-opacity-70;
}
</style>