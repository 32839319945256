<template>
  <div class="multi-select input input-bordered gap-2" @click.self="openSelector">
    <span class="btn btn-info btn-sm gap-2" v-for="(item, index) in modelValue" @click.prevent="removeItem(index)"
          :key="item">
      {{ item.name }}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
           class="inline-block w-4 h-4 stroke-current">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
    </span>
    <div class="options" v-if="showSelector">
      <div class="mt-2 mx-2">
        <input ref="searchInput" type="text" class="input input-bordered w-full" placeholder="Vyhľadať ..."
               v-model="search"/>
      </div>
      <ul class="menu bg-neutral mb-0">
        <li>
          <a v-for="option in filteredOptions" :class="{ active: itemExists(option.id) }" :key="option"
             @click="addItem(option)">{{ option.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showSelector: false,
    search: ''
  }),
  computed: {
    filteredOptions() {
      return this.options.filter(option => option.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods: {
    openSelector() {
      this.showSelector = !this.showSelector
      if (this.showSelector) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus()
        })
      }
    },
    itemExists(id) {
      return this.modelValue.find(item => item.id === id)
    },
    addItem(option) {
      if (!this.itemExists(option.id)) {
        this.$emit('update:modelValue', [...this.modelValue, option])
      } else {
        this.removeItem(this.modelValue.findIndex(item => item.id === option.id))
      }

      this.showSelector = false
    },
    removeItem(index) {
      this.$emit('update:modelValue', this.modelValue.filter((item, i) => i !== index))
    }
  },
  mounted() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.showSelector = false
      }
    })
  }
}
</script>

<style scoped lang="scss">
.multi-select {
  @apply px-2;
  @apply relative;
  @apply cursor-pointer;
  @apply flex;
  @apply items-center;
  @apply h-12;

  .options {
    @apply bg-neutral;
    @apply rounded-box;
    position: absolute;
    top: 54px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10000;

    .menu {
      max-height: 200px;
      overflow-y: auto;

      .active {
        @apply bg-info;
        @apply text-black;
      }
    }
  }
}
</style>