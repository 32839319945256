<template>
  <view-container :loading="loading">
    <div class="flex justify-between flex-wrap gap-2 mb-6">
      <h3 class="font-bold">Hodnotenia</h3>
    </div>

    <template v-if="!reward_system">
      <p>
        Nemáš nastavený žiadny systém hodnotenia.
      </p>
    </template>

    <template v-if="reward_system">

      <div class="stats bg-neutral stats-vertical lg:stats-horizontal shadow mb-6 w-full">
        <div class="stat">
          <div class="stat-title">Level</div>
          <div class="stat-value">{{ stats.level }}</div>
        </div>
        <div class="stat">
          <div class="stat-title">Celkové body</div>
          <div class="stat-value">{{ stats.reward_points_total }}</div>
          <div class="stat-desc">Mesačný priemer: {{ stats.average_points }}</div>
        </div>
        <div class="stat">
          <div class="stat-title">Počet bodov na level up</div>
          <div class="stat-value">{{ stats.pointsToLevelUp }}</div>
          <div class="stat-desc">Počet mesiacov do daľšieho levelu: {{ stats.monthsToLevelUp }}</div>
        </div>
      </div>

      <div class="table">
        <div class="row-header">
          <span>Mesiac</span>
          <span>Level</span>
          <span>Dochádzka</span>
          <span>{{ reward_system.name_b }}</span>
          <span>{{ reward_system.name_c }}</span>
          <span>{{ reward_system.name_d }}</span>
          <span>{{ reward_system.name_e }}</span>
          <span>Bonusové</span>
          <span>Body do kar. rastu</span>
        </div>
        <div class="row" v-for="rating in ratings" :key="rating.id" @click="selectRating(rating)">
          <span data-title="Mesiac">{{ rating.monthName }}<br><b>{{ rating.month }}. {{ rating.year }}</b></span>
          <span>
            <span class="badge badge-sm badge-primary">LEVEL {{ rating.level.name }}</span>
            <span class="badge badge-sm badge-warning" v-if="rating.level_changed">LEVEL UP</span>
            <span class="badge badge-sm badge-error" v-if="rating.is_frozen">FROZEN</span>
          </span>
          <span data-title="Dochádzka">{{ rating.point_a }}</span>
          <span data-title="B">{{ rating.point_b }}</span>
          <span data-title="C">{{ rating.point_c }}</span>
          <span data-title="D">{{ rating.point_d }}</span>
          <span data-title="E">{{ rating.point_e }}</span>
          <span data-title="Bonusové">{{ rating.bonus_points }}</span>
          <span data-title="Body do kar. rastu">{{ rating.final_points }} / 5</span>
        </div>
      </div>

      <modal ref="modal" :middle="false">
        <template v-if="selectedRating">
          <h3 class="font-bold text-lg">Hodnotenie za {{ selectedRating.month }}. {{ selectedRating.year }}</h3>

          <div class="my-2 p-5 bg-base-200 rounded-md">
            <div class="flex justify-between">
              <b>Dochádzka</b>
              <span class="badge badge-secondary">{{ selectedRating.point_a }}</span>
            </div>
            <p class="whitespace-pre-wrap text-neutral-400 text-sm">
              {{ selectedRating.note_a }}
            </p>
          </div>

          <div class="my-2 p-5 bg-base-200 rounded-md">
            <div class="flex justify-between">
              <b>{{ reward_system.name_b }}</b>
              <span class="badge badge-secondary">{{ selectedRating.point_b }}</span>
            </div>
            <p class="whitespace-pre-wrap text-neutral-400 text-sm">
              {{ selectedRating.note_b }}
            </p>
          </div>

          <div class="my-2 p-5 bg-base-200 rounded-md">
            <div class="flex justify-between">
              <b>{{ reward_system.name_c }}</b>
              <span class="badge badge-secondary">{{ selectedRating.point_c }}</span>
            </div>
            <p class="whitespace-pre-wrap text-neutral-400 text-sm">
              {{ selectedRating.note_c }}
            </p>
          </div>

          <div class="my-2 p-5 bg-base-200 rounded-md">
            <div class="flex justify-between">
              <b>{{ reward_system.name_d }}</b>
              <span class="badge badge-secondary">{{ selectedRating.point_d }}</span>
            </div>
            <p class="whitespace-pre-wrap text-neutral-400 text-sm">
              {{ selectedRating.note_d }}
            </p>
          </div>

          <div class="my-2 p-5 bg-base-200 rounded-md">
            <div class="flex justify-between">
              <b>{{ reward_system.name_e }}</b>
              <span class="badge badge-secondary">{{ selectedRating.point_e }}</span>
            </div>
            <p class="whitespace-pre-wrap text-neutral-400 text-sm">
              {{ selectedRating.note_e }}
            </p>
          </div>

          <div class="my-2 p-5 bg-primary-content/10 rounded-md">
            <div class="flex justify-between">
              <b>Celkové body</b>
              <span class="badge badge-secondary">{{ selectedRating.points_sum }} / 5</span>
            </div>
          </div>

          <div class="my-2 p-5 bg-base-200 rounded-md">
            <div class="flex justify-between">
              <b>Bonusové body</b>
              <span class="badge badge-secondary">{{ selectedRating.bonus_points }}</span>
            </div>
            <p class="whitespace-pre-wrap text-neutral-400 text-sm">
              {{ selectedRating.note_bonus }}
            </p>
          </div>

          <div class="my-2 p-5 bg-base-200 rounded-md">
            <div class="flex justify-between">
              <b>Poznámka</b>
            </div>
            <p class="whitespace-pre-wrap text-neutral-400 text-sm">
              {{ selectedRating.note }}
            </p>
          </div>

          <div class="my-2 p-5 bg-primary-content/10 rounded-md">
            <div class="flex justify-between">
              <b>Body do kar. rastu</b>
              <span class="badge badge-secondary">{{ selectedRating.final_points }} / 5</span>
            </div>
          </div>

        </template>
      </modal>

    </template>

  </view-container>
</template>

<script>
import { request } from '@/api/request'

export default {
  name: 'Rating',

  data: () => ({
    loading: 0,
    selectedRating: null,
    ratings: [],
    reward_system: {},
    stats: {}
  }),
  methods: {
    async loadRatings() {
      const data = await request('getEmployeeRatings')
      if (data && data.success) {
        this.ratings = data.ratings
        this.reward_system = data.reward_system
        this.stats = data.stats
      }
    },
    selectRating(rating) {
      this.selectedRating = rating
      this.$refs.modal.open()
    }
  },
  async created() {
    this.loading = 1
    await this.loadRatings()
    this.loading = 0
  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  color: #f5f5f5;

  .row-header {
    @apply bg-neutral;
    border-radius: 6px;
    font-size: 11px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    align-items: center;
    padding: 8px 16px;

    @media only screen and (max-width: 650px) {
      display: none;
    }

    span {
      display: block;
      padding: 4px;
      text-align: center;

      &:not(:last-child) {
        border-right: 1px solid #444;
      }
    }
  }

  .row-footer {
    background: #191919;
    border-radius: 6px;
    font-size: 11px;
    padding: 6px 0;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    align-items: center;

    span {
      display: block;
      padding: 4px;
      text-align: center;
      grid-column: 2 / span;

      &:not(:last-child) {
        border-right: 1px solid #444;
      }

      &:first-child {
        border-right: 0px solid #444;
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    align-items: center;
    margin: 12px 0px;
    min-height: 60px;
    padding: 8px 16px;
    @apply bg-neutral rounded-md;

    @media only screen and (max-width: 650px) {
      grid-template-columns: 1fr;
    }

    &:nth-child(odd) {
      @apply bg-opacity-40;
    }

    &:hover {
      @apply bg-primary;
      cursor: pointer;
    }

    & > span {
      display: block;
      padding: 8px;
      text-align: center;

      &.date {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media only screen and (max-width: 650px) {
          display: block;
          align-items: flex-start;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid #444;
      }

      @media only screen and (max-width: 650px) {
        text-align: left;
        &:not(:last-child) {
          border-right: 0px solid #444;
        }
        &:before {
          content: attr(data-title);
          font-size: 11px;
          color: #999;
          display: block;
          margin-bottom: 6px;
        }
      }
    }
  }
}

</style>
