<template>
  <view-container :loading="loading">
    <div class="flex justify-between mb-6">
      <h3 class="font-bold">Menu</h3>
    </div>

    <div class="stats bg-neutral stats-vertical lg:stats-horizontal shadow mb-6" v-if="menuInfo">
      <div class="stat">
        <div class="stat-title">Moje číslo</div>
        <div class="stat-value">{{ menuInfo.employeeNumber }}</div>
      </div>
      <div class="stat">
        <div class="stat-title">Nezaplatené obedy</div>
        <div class="stat-value">{{ menuInfo.priceNotPaidMenus }} €</div>
        <div class="stat-desc">Počet: {{ menuInfo.countNotPaidMenus }}</div>
      </div>
    </div>

    <div v-if="menus && menus.length > 0">
      <div
        v-for="menu in menus"
        :key="'menu' + menu.id"
        :class="['menu', { inactive: isPastTimeMenu(menu.date) }]"
      >
        <h3 class="text-sm font-bold">{{ menu.date_formatted }}</h3>
        <div class="menu__soups">
          <div
            class="menu__meal"
            v-for="soup in menu.soups"
            :key="'soup' + soup.id"
            :class="{ active: isActiveMealSoup(menu.id, soup.id) }"
            @click="selectMenuSoup(menu.id, soup.id, menu.date)"
          >
            <b>Polievka:</b> {{ soup.name }}
          </div>
        </div>
        <div class="menu__foods">
          <div
            class="menu__meal"
            v-for="meal in menu.meals"
            :key="'meal' + meal.id"
            :class="{ active: isActiveMeal(menu.id, meal.id) }"
            @click="selectMenuMeal(menu.id, meal.id, menu.date)"
          >
            <b>{{ meal.number }}.</b> {{ meal.name }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Žiadne menu nie je k dispozícii.</p>
    </div>
  </view-container>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import { request } from '@/api/request'

export default {
  name: 'MealMenu',
  data: () => ({
    menus: null,
    employeeMenus: null,
    selectedEmployee: null,
    menuInfo: null,
    loading: 1
  }),
  computed: {
    ...mapState({ employee: (state) => state.employee.employeeData })
  },
  methods: {
    isPastTimeMenu(date) {
      let timeNow = dayjs()
      return timeNow.isAfter(dayjs().hour(7).minute(30)) && timeNow.isSame(dayjs(date), 'day')
    },
    async loadMenu() {
      const data = await request('getMenus')
      if (data && data.success) {
        this.menus = data.menus
        this.employeeMenus = data.employeeMenus
        this.menuInfo = data.menuInfo
      }
    },
    async selectMenuMeal(menuId, mealId, mealDate) {
      if (this.isPastTimeMenu(mealDate)) {
        this.$store.dispatch('error/handler', {
          message: 'Dnes už nie je možné objednať jedlo. Objednávanie je možné iba do 7:30 daného dňa',
          color: 'alert-error'
        })
        return
      }
      const data = await request('selectMenuMeal', {
        menuId: menuId,
        mealId: mealId
      })
      if (data && data.success) {
        this.$store.dispatch('error/handler', {
          message: 'Výber jedla bol uložený.',
          color: 'alert-success'
        })
        this.loadMenu()
      }
    },
    async selectMenuSoup(menuId, soupId, mealDate) {
      if (this.isPastTimeMenu(mealDate)) {
        this.$store.dispatch('error/handler', {
          message: 'Dnes už nie je možné objednať jedlo. Objednávanie je možné iba do 7:30 daného dňa',
          color: 'alert-error'
        })
        return
      }
      const data = await request('selectMenuSoup', {
        menuId: menuId,
        soupId: soupId
      })
      if (data && data.success) {
        this.$store.dispatch('error/handler', {
          message: 'Výber jedla bol uložený.',
          color: 'alert-success'
        })
        this.loadMenu()
      }
    },
    isActiveMeal(menuId, mealId) {
      return this.employeeMenus.find(
        (item) => item.menu_id == menuId && item.meal_id == mealId
      )
    },
    isActiveMealSoup(menuId, soupId) {
      return this.employeeMenus.find(
        (item) => item.menu_id == menuId && item.soup_id == soupId
      )
    }
  },
  async created() {
    this.loading = 1
    await this.loadMenu()
    this.loading = 0
  }
}
</script>

<style lang="scss">
.menu {
  @apply bg-base-300;
  @apply p-6;
  @apply mb-6;
  @apply rounded-lg;
  @apply gap-2;

  &.inactive {
    @apply opacity-50;
  }

  &__soups, &__foods {
    @apply flex;
    @apply flex-col;
    @apply gap-1;
  }

  &__meal {
    @apply bg-neutral;
    @apply p-4;
    @apply rounded-lg;
    @apply cursor-pointer;

    &:hover {
      @apply bg-neutral-content/20;
    }

    &.active {
      @apply bg-primary;
      @apply text-white;
    }
  }
}
</style>
