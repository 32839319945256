import localStorage from '@/plugins/localStorage'
import store from '@/store'
import router from '@/router'

export const request = async (handler, data) => {
  let response = await fetch(import.meta.env.VITE_APP_API_URL + '/' + handler, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.get('sessionToken')
    }
  })

  const responseJson = await response.json()

  if (response.ok) {
    return responseJson
  } else {
    store.dispatch('employee/logout')
    router.push('/')
    store.dispatch('error/handler', {
      message: 'Prosím prihláste sa znova.',
      color: 'alert-warning'
    })
  }
}
