<template>
  <div class="centered-container">
    <router-view v-slot="{ Component, route }" class="view">
      <transition name="slide-left" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'ViewMiddle',
}
</script>

<style lang="scss">
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
}

.view {
  position: absolute;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-left-enter {
  opacity: 0;
  transform: translate(0, 30px);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate(0, -30px);
}
</style>
