<template>
  <label class="btn join-item" :class="{ 'btn-primary': checked, 'btn-neutral': !checked }">
    <input class="hidden" type="radio" :value="value" @input="change" :checked="checked"/>
    {{ name }}
  </label>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    checked() {
      return this.modelValue == this.value
    }
  },
  methods: {
    change(e) {
      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>

