<template>
  <div class="drawer">
    <input id="sidemenu" type="checkbox" class="drawer-toggle" v-model="openMenu"/>
    <div class="drawer-content">

      <div class="navbar max-h-5 bg-neutral">
        <div class="flex-none">
          <router-link :to="{ name: 'dashboard' }" class="btn btn-ghost normal-case text-xl">DR</router-link>
        </div>
        <div class="flex-none">
          <button class="btn btn-ghost md:hidden" @click="openMenu = true">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-menu">
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </button>
          <ul class="menu bg-neutral menu-horizontal gap-0.5 rounded-box mb-0 max-md:hidden">
            <li>
              <router-link :to="{ name: 'dashboard' }" exact-active-class="focus">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
                </svg>
                Domov
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'reporting' }" exact-active-class="focus">
                <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                  <path d="M9 6L20 6" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M9 12L20 12" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M9 18L20 18" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
                Reporty
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ratings' }" exact-active-class="focus">
                <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     stroke="currentColor">
                  <path
                    d="M14.2718 10.445L18 2M9.31612 10.6323L5 2M12.7615 10.0479L8.835 2M14.36 2L13.32 4.5M6 16C6 19.3137 8.68629 22 12 22C15.3137 22 18 19.3137 18 16C18 12.6863 15.3137 10 12 10C8.68629 10 6 12.6863 6 16Z"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                Hodnotenia
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'attendance' }" exact-active-class="focus">
                <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                  <path
                    d="M15 4V2M15 4V6M15 4H10.5M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10H3Z"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M3 10V6C3 4.89543 3.89543 4 5 4H7" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M7 2V6" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M21 10V6C21 4.89543 20.1046 4 19 4H18.5" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                Dochádzka
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'menu' }" exact-active-class="focus">
                <svg class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" fill="none"
                     xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                  <path d="M6 20H9M12 20H9M9 20V15" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path d="M17 20V12C17 12 19.5 11 19.5 9C19.5 7.24264 19.5 4.5 19.5 4.5"
                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M17 8.5V4.5" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                  <path
                    d="M4.49999 11C5.49991 13.1281 8.99999 15 8.99999 15C8.99999 15 12.5001 13.1281 13.5 11C14.5795 8.70257 13.5 4.5 13.5 4.5L4.49999 4.5C4.49999 4.5 3.42047 8.70257 4.49999 11Z"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                Menu
              </router-link>
            </li>
          </ul>
        </div>
        <div class="flex-none ml-auto" v-if="employee">
          <div class="dropdown dropdown-end" style="position:initial;">
            <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
              <div class="indicator">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                </svg>
                <span v-if="unreadNotifications.length" class="badge badge-xs badge-primary indicator-item z-0">{{ unreadNotifications.length }}</span>
              </div>
            </div>
            <div tabindex="0" class="mt-3 z-[1] p-2 shadow dropdown-content rounded-lg bg-neutral w-[400px]" style="inset-inline-end: 8px;">
              <span class="text-xs font-bold">Notifikácie</span>
              <div class="flex gap-2 mt-2">
                <div class="flex flex-col gap-2" v-if="limitNotifications.length">
                  <router-link v-for="notification in limitNotifications" :key="'n' + notification.id"
                               class="flex gap-2 p-2 rounded-lg hover:bg-base-300 hover:cursor-pointer"
                               :class="{ 'bg-neutral-800': !notification.is_readed, 'bg-neutral-900': notification.is_readed }"
                               :to="{ name: 'notifications' }">
                    <div>
                      <svg v-if="notification.type.id === 2" xmlns="http://www.w3.org/2000/svg" class="stroke-warning shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                      </svg>
                      <svg v-if="notification.type.id === 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info shrink-0 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                    </div>
                    <div>
                      <div class="text-sm font-bold">{{ notification.name }}</div>
                      <div class="text-xs text-gray-400">{{ formattedDescription(notification.description) }}...</div>
                      <div class="flex gap-2 mt-1">
                        <div class="text-xs text-gray-500">{{ formatBefore(notification.updated_at) }}</div>
                        <div class="text-gray-500 text-xs" v-if="notification.is_readed">Prečítané</div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div v-else class="text-center text-gray-500 p-2">
                  Žiadne neprečítané notifikácie
                </div>
              </div>

              <router-link :to="{ name: 'notifications' }" class="btn btn-xs w-full" v-if="limitNotifications.length > 5">Zobraziť všetky</router-link>
            </div>
          </div>

          <div class="dropdown dropdown-end">
            <div tabindex="0" role="button" class="btn btn-ghost">
              <div v-if="employee.photo" class="avatar">
                <div class="w-6 rounded-full">
                  <img :src="employee.photo" alt=""/>
                </div>
              </div>
              {{ employee.fullName }}
            </div>
            <ul tabindex="0" class="mt-3 z-[1] p-2 shadow menu dropdown-content bg-neutral w-52">
              <li>
                <router-link :to="{ name: 'settings' }">
                  <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                    <path
                      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M19.6224 10.3954L18.5247 7.7448L20 6L18 4L16.2647 5.48295L13.5578 4.36974L12.9353 2H10.981L10.3491 4.40113L7.70441 5.51596L6 4L4 6L5.45337 7.78885L4.3725 10.4463L2 11V13L4.40111 13.6555L5.51575 16.2997L4 18L6 20L7.79116 18.5403L10.397 19.6123L11 22H13L13.6045 19.6132L16.2551 18.5155C16.6969 18.8313 18 20 18 20L20 18L18.5159 16.2494L19.6139 13.598L21.9999 12.9772L22 11L19.6224 10.3954Z"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  Nastavenia
                </router-link>
              </li>
              <li>
                <a href="#" @click="logout">
                  <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                    <path d="M12 12H19M19 12L16 15M19 12L16 9" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M19 6V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V18"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  Odhlásiť
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <router-view/>
    </div>
    <div class="drawer-side">
      <label for="sidemenu" aria-label="close sidebar" class="drawer-overlay"></label>
      <ul class="menu rounded-none p-4 w-80 min-h-full bg-base-200 text-base-content gap-0.5">
        <li>
          <router-link :to="{ name: 'dashboard' }" exact-active-class="focus" @click="openMenu = false">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
            </svg>
            Domov
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'reporting' }" exact-active-class="focus" @click="openMenu = false">
            <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
              <path d="M9 6L20 6" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M9 12L20 12" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path d="M9 18L20 18" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
            Reporty
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ratings' }" exact-active-class="focus" @click="openMenu = false">
            <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 stroke="currentColor">
              <path
                d="M14.2718 10.445L18 2M9.31612 10.6323L5 2M12.7615 10.0479L8.835 2M14.36 2L13.32 4.5M6 16C6 19.3137 8.68629 22 12 22C15.3137 22 18 19.3137 18 16C18 12.6863 15.3137 10 12 10C8.68629 10 6 12.6863 6 16Z"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            Hodnotenia
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'attendance' }" exact-active-class="focus" @click="openMenu = false">
            <svg class="h-5 w-5" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
              <path d="M15 4V2M15 4V6M15 4H10.5M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10H3Z"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M3 10V6C3 4.89543 3.89543 4 5 4H7" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path d="M7 2V6" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path d="M21 10V6C21 4.89543 20.1046 4 19 4H18.5" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            Dochádzka
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'menu' }" exact-active-class="focus" @click="openMenu = false">
            <svg class="h-5 w-5" viewBox="0 0 24 24" stroke-width="1.5" fill="none"
                 xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
              <path d="M6 20H9M12 20H9M9 20V15" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path d="M17 20V12C17 12 19.5 11 19.5 9C19.5 7.24264 19.5 4.5 19.5 4.5"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M17 8.5V4.5" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path
                d="M4.49999 11C5.49991 13.1281 8.99999 15 8.99999 15C8.99999 15 12.5001 13.1281 13.5 11C14.5795 8.70257 13.5 4.5 13.5 4.5L4.49999 4.5C4.49999 4.5 3.42047 8.70257 4.49999 11Z"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            Menu
          </router-link>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import { request } from '@/api/request'
import dayjs from 'dayjs'

export default {
  name: 'ViewAccount',
  data: () => ({
    openMenu: false,
    notifications: []
  }),
  methods: {
    formattedDescription(description) {
      let result = description.replace(/&nbsp;|\n/g, " ").replace(/<\/?[^>]+>/ig, " ");
      return result.substring(0, 110);
    },
    formatBefore(date, locale = 'sk') {
      if (!date) {
        return
      }

      return dayjs(date).locale(locale).fromNow()
    },
    logout() {
      this.$store.dispatch('employee/logout')
      this.$router.push('/')
    },
    async fetchData() {
      const data = await request('getEmployeeNotifications')
      if (data && data.success) {
        this.notifications = data.notifications
      }
    }
  },
  computed: {
    limitNotifications() {
      return this.notifications.slice(0, 5)
    },
    unreadNotifications() {
      return this.notifications.filter(notification => !notification.is_readed)
    },
    ...mapState({
      employee: state => state.employee.employeeData
    })
  },
  async created() {
    await this.fetchData()
  }
}
</script>

<style lang="scss">

</style>
