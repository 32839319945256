import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'

import 'dayjs/locale/sk'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import '@/assets/app.scss'

import ViewContainer from '@/components/layout/ViewContainer.vue'
import Spinner from '@/components/Spinner.vue'
import Snackbar from '@/components/Snackbar.vue'
import Modal from '@/components/Modal.vue'

dayjs.extend(relativeTime)

const app = createApp(App)

app.component('ViewContainer', ViewContainer)
app.component('Spinner', Spinner)
app.component('Snackbar', Snackbar)
app.component('Modal', Modal)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_APP_VERSION,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.browserTracingIntegration()
    ],
    debug: import.meta.env.DEV,
    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true
    },
    // Vue specific
    logErrors: import.meta.env.DEV,
    attachProps: true,
    attachStacktrace: true
  })
}

app.use(store)
  .use(router)
  .mount('#app')


