<template>
  <view-container :loading="loading">
    <div class="flex justify-between flex-wrap gap-2 mb-6">
      <h3 class="font-bold">Reporting</h3>

      <div class="join ml-2">
        <button class="btn btn-sm btn-neutral join-item" @click="goBackwards"> &lt;</button>
        <span class="btn btn-sm btn-neutral join-item">{{ formatDatetime(now, 'MMMM YYYY') }}</span>
        <button class="btn btn-sm btn-neutral join-item" @click="goForward" :disabled="isSameMonth"> &gt;</button>
      </div>
    </div>
    <div>
      <div class="table">
        <div
          class="row"
          v-for="(day, key) in dayReports"
          :key="key"
        >
          <div class="row-header" :class="{ today: isToday(day.date) }">
            <div class="flex gap-4">
              <span class="date">
                <span class="day-name">{{ formatDatetime(day.date, 'ddd') }}</span>
                {{ formatDatetime(day.date, 'D. M. YYYY') }}
              </span>
              <button class="btn btn-primary btn-xs" @click="createNewReport(day)">Pridať záznam</button>
            </div>

            <span class="badge badge-sm badge-neutral" v-if="day.reports.length">
              {{ formatMinutes(sumBy(day.reports, 'duration')) }}
            </span>
          </div>
          <div class="row-reports" v-if="day.reports.length">
            <div
              class="row-report"
              v-for="report in sortDayReports(day.reports)"
              :key="'report'+report.id"
              @click="editReport(day, report)"
            >
              <div>
                <div class="flex gap-2">
                  <span class="badge text-white" :style="{ backgroundColor: report.company.color, borderColor: report.company.color }">
                    {{ report.company.code }}
                  </span>
                  <span class="badge badge-neutral" :class="report.type.color">{{ report.type.name }}</span>
                  <span class="badge badge-neutral" v-if="report.location">{{ report.location.name }}</span>
                </div>
                <div class="pt-2">
                  <b>
                    {{ report.subject.parent ? report.subject.parent.name + ' » ' : '' }} {{ report.subject.name }}
                  </b>
                  <p class="whitespace-pre-wrap py-2 text-neutral-400">{{ report.note }}</p>
                  <span class="badge badge-secondary mr-2" v-for="tag in report.tags" :key="'tag'+tag.id">{{ tag.name }}</span>
                </div>
              </div>
              <span class="badge badge-neutral" :class="{ 'badge-warning': report.duration / 60 > 12 }">{{ formatMinutes(report.duration) }}</span>
            </div>
          </div>
        </div>
        <div class="row-footer" v-if="allReports.length">
          <span class="flex gap-2">
              <div class="badge" v-for="item in summary" :key="'summary' + item.id" :style="{ backgroundColor: item.company.color, borderColor: item.company.color }">
                <b>{{ item.company.code }}: </b>
                <span>
                  {{ formatMinutes(item.duration) }}
                  ({{ item.percentage }} %)
                </span>
              </div>
          </span>
          <span class="badge badge-neutral">{{ formatMinutes(allDuration) }}</span>
        </div>
      </div>
    </div>

    <modal ref="modal" @closed="closeModal" size="w-11/12 max-w-5xl" :middle="false">
      <template v-if="selectedDay">
        <h3 class="font-bold text-lg" v-if="selectedDay">
          {{ isEditingReport ? 'Upraviť záznam' : 'Pridať nový záznam' }}
          <span>{{ formatDatetime(selectedDay.date, 'dd') }}</span>
          {{ formatDatetime(selectedDay.date, 'D. M. YYYY') }}
        </h3>
        <div class="body">
          <form @submit.prevent="formAction">
            <div class="flex flex-wrap gap-6 max-md:gap-0">
              <div class="form-control">
                <label class="label">
                  <span class="label-text">Firma</span>
                </label>
                <div class="join">
                  <radio-button
                    v-for="company in companies"
                    :key="'company' + company.id"
                    :name="company.code"
                    :value="company.id"
                    v-model="createReportData.company_id"
                  />
                </div>
              </div>
              <div class="form-control">
                <label class="label">
                  <span class="label-text">Typ</span>
                </label>
                <div class="join">
                  <radio-button
                    v-for="type in reportTypes"
                    :key="'type' + type.id"
                    :name="type.name"
                    :value="type.id"
                    v-model="createReportData.type_id"
                  />
                </div>
              </div>
              <div class="form-control" v-if="createReportData.type_id == 1">
                <label class="label">
                  <span class="label-text">Lokácia</span>
                </label>
                <select class="select select-bordered w-full" v-model="createReportData.location_id">
                  <option
                    v-for="location in reportLocations"
                    :label="location.name"
                    :key="location.id"
                    :value="location.id"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex flex-wrap gap-2 items-end">
              <div class="form-control">
                <label class="label">
                  <span class="label-text">Hodiny</span>
                </label>
                <input
                  class="input input-bordered w-full max-w-[80px] [appearance:textfield]"
                  type="number"
                  v-model="createReportData.hours"
                  min="0"
                  required
                />
              </div>
              <div class="form-control">
                <label class="label">
                  <span class="label-text">Minúty</span>
                </label>
                <input
                  class="input input-bordered w-full max-w-[80px] [appearance:textfield]"
                  type="number"
                  v-model="createReportData.minutes"
                  min="0"
                  max="59"
                  required
                />
              </div>
              <div class="flex flex-wrap gap-2">
                <div class="join">
                  <button type="button" @click="addDuration(i)" class="btn join-item btn-neutral max-md:btn-xs" v-for="i in [60, 30, 15, 5]" :key="'time'+i">+{{ i }}</button>
                  <span class="btn pointer-events-none join-item">minút</span>
                </div>
                <button type="button" @click="createReportData.hours = createReportData.minutes = 0" class="btn btn-neutral">Reset</button>
              </div>
            </div>

            <div class="form-control">
              <label class="label">
                <span class="label-text">Predmet</span>
              </label>
              <select class="select select-bordered w-full" v-model="createReportData.subject_id" required>
                <option value="">-- Vybrať predmet --</option>
                <component
                  :is="subject.children.length ? 'optgroup' : 'option'"
                  v-for="subject in reportSubjects"
                  :label="subject.name"
                  :key="subject.id"
                  :value="subject.id"
                >
                  <template v-if="subject.children.length">
                    <option
                      v-for="subjectChildren in subject.children"
                      :key="subjectChildren.id"
                      :value="subjectChildren.id"
                    >
                      {{ subject ? subject.name + ' » ' : '' }} {{ subjectChildren.name }}
                    </option>
                  </template>
                  <template v-else>
                    {{ subject.name }}
                  </template>
                </component>
              </select>
            </div>

            <div class="mt-1 flex items-center gap-2" v-if="mostUsedMySubjects && isCreatingNewReport">
              <button type="button" @click="applySubject(subject.id)" class="btn btn-xs" v-for="subject in mostUsedMySubjects" :key="'subject'+subject.id">
                {{ subject.parent ? subject.parent.name + ' » ' : '' }} {{ subject.name }}
              </button>
            </div>

            <div class="form-control">
              <label class="label">
                <span class="label-text">Tagy</span>
              </label>
              <multi-select v-model="createReportData.tags" :options="reportTags"></multi-select>
            </div>

            <div class="mt-1 flex items-center gap-2" v-if="mostUsedMyTags && isCreatingNewReport">
              <button type="button" @click="applyTag(tag.id)" class="btn btn-xs" v-for="tag in mostUsedMyTags" :key="'tag'+tag.id">
                {{ tag.name }}
              </button>
            </div>

            <div class="form-control">
              <label class="label">
                <span class="label-text">Poznámka</span>
              </label>
              <textarea ref="note" class="textarea textarea-bordered" v-model="createReportData.note" minlength="5" @focus="resizeNoteTextarea" @input="resizeNoteTextarea" required></textarea>
            </div>

            <div class="card-actions mt-6">
              <button class="btn btn-primary" type="submit" :disabled="lockUpdatingReport || lockCreatingNewReport">
                <span v-if="lockUpdatingReport || lockCreatingNewReport" class="loading loading-spinner"></span>
                {{ isEditingReport ? 'Uložiť' : 'Vytvoriť' }}
              </button>
              <button type="button" class="btn btn-neutral" @click="$refs.modal.close()">
                Zrušiť
              </button>
            </div>
          </form>
        </div>
      </template>
    </modal>
  </view-container>
</template>

<script>
import { request } from '@/api/request'
import dayjs from 'dayjs'
import { chain, cloneDeep, orderBy, sumBy } from 'lodash'
import RadioButton from '@/components/RadioButton.vue'
import MultiSelect from '@/components/MultiSelect.vue'

export default {
  name: 'EmployeeReporting',
  components: { MultiSelect, RadioButton },

  data: () => ({
    loading: 0,
    now: null,

    dayReports: [],
    companies: [],
    reportTypes: [],
    reportLocations: [],
    reportSubjects: [],
    reportTags: [],
    mostUsedMySubjects: [],
    mostUsedMyTags: [],
    selectedDay: null,

    isEditingReport: false,
    isCreatingNewReport: false,
    createReportData: null,

    lockCreatingNewReport: false,
    lockUpdatingReport: false,

    emptyReport: {
      hours: '',
      minutes: '',
      company_id: '',
      type_id: '',
      note: '',
      subject_id: '',
      location_id: '',
      tags: []
    }
  }),
  computed: {
    isSameMonth() {
      return dayjs(this.now).isSame(dayjs(), 'month')
    },
    allReports() {
      return this.dayReports.map((dayReport) => dayReport.reports).flat()
    },
    allDuration() {
      return this.sumBy(this.allReports, 'duration')
    },
    summary() {
      if (!this.allReports.length) {
        return []
      }

      return chain(this.allReports)
        .groupBy('company_id')
        .map((value) => {
          const companyDuration = this.sumBy(value, 'duration')

          return {
            company: Object.values(value)[0].company,
            duration: companyDuration,
            percentage: Math.round(companyDuration / this.sumBy(this.allReports, 'duration') * 100) || 0
          }
        })
    }
  },
  watch: {
    'createReportData.type_id': function (newValue, oldValue) {
      if (newValue && this.createReportData.type_id != 1) {
        this.createReportData.location_id = ''
      }

      if (this.createReportData.type_id == 1
        && !this.createReportData.location_id
      ) {
        this.createReportData.location_id = 1
      }
    }
  },
  methods: {
    applySubject(subjectId) {
      this.createReportData.subject_id = subjectId
    },
    applyTag(tagId) {
      if (this.createReportData.tags.find((tag) => tag.id === tagId)) {
        return
      }

      const tag = this.reportTags.find((tag) => tag.id === tagId)
      this.createReportData.tags.push(tag)
    },
    resizeNoteTextarea() {
      let element = this.$refs.note

      if (element.scrollHeight > 74) {
        element.style.height = element.scrollHeight + 2 + 'px'
      }
    },
    addDuration(value) {
      if (!this.createReportData.minutes) {
        this.createReportData.minutes = 0
      }

      if (!this.createReportData.hours) {
        this.createReportData.hours = 0
      }

      // if minutes is more then 60, add to hours but remainder to minutes
      if (value + this.createReportData.minutes >= 60) {
        this.createReportData.hours = parseInt(this.createReportData.hours) + 1
        this.createReportData.minutes = (value + this.createReportData.minutes) - 60
      } else {
        this.createReportData.minutes = parseInt(this.createReportData.minutes) + value
      }
    },
    sumBy(array, key) {
      return sumBy(array, key)
    },
    isToday(date) {
      return dayjs(date).isSame(dayjs(), 'day')
    },
    closeModal() {
      this.selectedDay = null
      this.isEditingReport = this.isCreatingNewReport = false
    },
    createNewReport(day) {
      this.isEditingReport = false
      this.isCreatingNewReport = true
      this.createReportData = cloneDeep(this.emptyReport)
      this.selectedDay = day
      this.$refs.modal.open()
    },
    editReport(day, report) {
      this.isCreatingNewReport = false
      this.isEditingReport = true
      this.createReportData = { ...report }
      this.selectedDay = day
      this.$refs.modal.open()
    },
    formAction() {
      if (this.isEditingReport) {
        this.updateReport()
      } else {
        this.createReport()
      }
    },
    async updateReport() {
      if (
        !(
          this.createReportData.note &&
          this.createReportData.subject_id &&
          this.createReportData.company_id &&
          this.createReportData.type_id
        )
        && (this.createReportData.type_id == 1 && this.createReportData.location_id)
      ) {
        this.$store.dispatch('error/handler', {
          message: 'Vyplňte všetky polia',
          color: 'alert-warning'
        })
        return
      }

      this.lockUpdatingReport = true
      const data = await request('updateReport', {
        id: this.createReportData.id,
        date: this.selectedDay.date,
        hours: this.createReportData.hours,
        minutes: this.createReportData.minutes,
        companyId: this.createReportData.company_id,
        typeId: this.createReportData.type_id,
        subjectId: this.createReportData.subject_id,
        tags: this.createReportData.tags.map((tag) => tag.id),
        note: this.createReportData.note,
        locationId: this.createReportData.location_id
      })

      if (data && data.success) {
        await this.loadEmployeeReports()

        this.createReportData = cloneDeep(this.emptyReport)

        this.$store.dispatch('error/handler', {
          message: 'Report bol uložený.',
          color: 'alert-success'
        })
        this.$refs.modal.close()
      } else {
        this.$store.dispatch('error/handler', {
          message: 'Nepodarilo sa uložiť report.',
          color: 'alert-error'
        })
      }

      this.lockUpdatingReport = false
    },
    async createReport() {
      if (
        !(
          this.createReportData.note &&
          this.createReportData.subject_id &&
          this.createReportData.company_id &&
          this.createReportData.type_id
        )
        && (this.createReportData.type_id == 1 && this.createReportData.location_id)
      ) {
        this.$store.dispatch('error/handler', {
          message: 'Vyplňte všetky polia.',
          color: 'alert-warning'
        })
        return
      }

      this.lockCreatingNewReport = true
      const data = await request('createReport', {
        date: this.selectedDay.date,
        hours: this.createReportData.hours,
        minutes: this.createReportData.minutes,
        companyId: this.createReportData.company_id,
        typeId: this.createReportData.type_id,
        subjectId: this.createReportData.subject_id,
        tags: this.createReportData.tags.map((tag) => tag.id),
        note: this.createReportData.note,
        locationId: this.createReportData.location_id
      })

      if (data && data.success) {
        await this.loadEmployeeReports()

        this.createReportData = { ...this.emptyReport }

        this.$store.dispatch('error/handler', {
          message: 'Report bol vytvorený.',
          color: 'alert-success'
        })

        this.$refs.modal.close()
      } else {
        this.$store.dispatch('error/handler', {
          message: 'Nepodarilo sa vytvoriť report.',
          color: 'alert-error'
        })
      }

      this.lockCreatingNewReport = false
    },
    formatDatetime(date, format = 'D. M. YYYY HH:mm', locale = 'sk') {
      if (!date) {
        return
      }
      return dayjs(date).locale(locale).format(format)
    },
    formatMinutes(min) {
      if (min <= 0) {
        return
      }
      let hours = min / 60
      let rhours = Math.floor(hours)
      let minutes = (hours - rhours) * 60
      let rminutes = Math.round(minutes)
      return (
        rhours.toString().padStart(2, '0') +
        ':' +
        rminutes.toString().padStart(2, '0')
      )
    },
    sortDayReports(reports) {
      return orderBy(reports, ['created_at'], ['desc'])
    },
    async loadEmployeeReports() {
      const data = await request('getEmployeeReports', {
        'month': this.now.format('M'),
        'year': this.now.format('YYYY')
      })
      if (data && data.success) {
        this.dayReports = orderBy(data.dayReports.filter((report) => dayjs(report.date).isBefore() || dayjs(report.date).isSame()), ['date'], ['desc'])
        this.companies = data.companies
        this.reportTypes = data.reportTypes
        this.reportLocations = data.reportLocations
        this.reportSubjects = data.reportSubjects
        this.reportTags = data.reportTags
        this.mostUsedMySubjects = data.mostUsedMySubjects
        this.mostUsedMyTags = data.mostUsedMyTags
      }
    },
    async goBackwards() {
      this.now = dayjs(this.now).subtract(1, 'months')

      this.loading = 1
      await this.loadEmployeeReports()
      this.loading = 0
    },
    async goForward() {
      this.now = dayjs(this.now).add(1, 'months')

      this.loading = 1
      await this.loadEmployeeReports()
      this.loading = 0
    }
  },
  async created() {
    this.now = dayjs()
    this.loading = 1
    await this.loadEmployeeReports()
    this.loading = 0
  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;

  .row {
    @apply flex;
    @apply flex-col;

    &:first-of-type {
      .row-header {
        .date {
          &:before {
            top: 45%;
          }

          &:after {
            top: 10%;
          }
        }
      }
    }

    .row-header {
      @apply flex;
      @apply justify-between;
      @apply items-center;
      @apply py-6;
      @apply px-4;
      @apply relative;

      &.today {
        @apply pt-0;
        .date {
          &:after {
            @apply bg-primary;
          }
        }
      }

      .date {
        @apply flex;
        @apply items-center;
        @apply font-semibold;
        @apply pl-2;
        @apply min-w-[150px];
        @apply text-base;
        @apply text-white;

        &:before {
          @apply h-[80px];
          @apply bg-neutral;
          @apply absolute;
          @apply w-[2px];
          content: "";
          left: 0px;
          z-index: -1;
        }

        &:after {
          @apply rounded-full;
          @apply bg-neutral-500;
          @apply absolute;
          content: "";
          @apply w-[16px];
          @apply h-[16px];
          left: -7px;
          top: 41%;
        }

        .day-name {
          @apply mr-2;
        }
      }
    }

    .row-reports {
      @apply flex;
      @apply flex-col;
      @apply pl-4;
      @apply border-l-2;
      @apply border-neutral;
    }

    .row-report {
      @apply border-r;
      @apply border-l;
      @apply border-neutral-700;
      @apply p-4;
      @apply flex;
      @apply justify-between;
      @apply border-b;

      .green {
        @apply bg-green-700;
        @apply border-green-700;
        @apply text-white;
      }

      .yellow {
        @apply bg-yellow-600;
        @apply border-yellow-600;
        @apply text-white;
      }

      .blue {
        @apply bg-blue-700;
        @apply border-blue-700;
        @apply text-white;
      }

      &:first-of-type {
        @apply rounded-tl-md rounded-tr-md;
        @apply border-t;
      }

      &:last-of-type {
        @apply rounded-bl-md rounded-br-md;
        @apply border-b;
      }

      &:hover {
        @apply bg-neutral-800;
        @apply cursor-pointer;
      }
    }
  }

  .row-footer {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply p-4;
    @apply gap-4;
  }
}
</style>
