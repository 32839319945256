const state = {
  lastError: null,
  color: 'alert-info',
  type: 'center',
  history: []
}

const mutations = {
  set(state, error) {
    state.lastError = error
    state.history.push(error)
  },
  setType(state, type) {
    state.type = type
  },
  setColor(state, color) {
    state.color = color
  },
  clear() {
    state.lastError = null
    state.history = []
  }
}

const actions = {
  handler({ commit }, { message, color, type }) {

    if (color) {
      commit('setColor', color)
    }

    if (type) {
      commit('setType', type)
    }

    if (message) {
      commit('set', message)
    } else {
      commit('set', 'Niečo sa pokazilo.')
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
