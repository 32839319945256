import { createStore } from 'vuex'
import employee from '@/store/modules/employee';
import error from '@/store/modules/error';

export default createStore({
  modules: {
    employee,
    error
  }
})
