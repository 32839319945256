<template>
  <transition name="view-container" appear>
    <div v-if="loading > 0" class="view-container-loading">
      <spinner :size="30"></spinner>
    </div>
    <div v-else class="view-container">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ViewContainer',
  props: {
    loading: Number,
  },
}
</script>

<style lang="scss">
.title {
  display: flex;
  align-items: center;
  margin: 20px 0;
  min-height: 28px;

  h3 {
    margin: 0;
  }
}

.view-container-loading {
  display: flex;
  width: 100%;
  height: calc(100vh - 70px);
  align-items: center;
  justify-content: center;
}

.view-container {
  width: 100%;
  max-width: 1400px;
  margin: 20px auto;
  padding: 0 20px;
}

.view-container-leave-active {
  display: none;
}

.view-container-enter-active {
  opacity: 0;
  transform: translate3d(0, 150px, 0);
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.view-container-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
</style>
