<template>
  <router-view/>

  <snackbar v-model:active="showSnackbar" :color="color" :position="type">
    {{ lastError }}
  </snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      showSnackbar: false
    }
  },
  computed: {
    ...mapState({
      history: (state) => state.error.history,
      lastError: (state) => state.error.lastError,
      color: (state) => state.error.color,
      type: (state) => state.error.type
    })
  },
  watch: {
    history: {
      deep: true,
      handler: function () {
        this.showSnackbar = true
      }
    }
  }
}
</script>
