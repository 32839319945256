import { createRouter, createWebHistory } from 'vue-router'
import ViewAccount from '@/ViewAccount.vue'
import Login from '@/views/Login.vue'
import Reporting from '@/views/account/Reporting.vue'
import Dashboard from '@/views/account/Dashboard.vue'
import ViewMiddle from '@/ViewMiddle.vue'
import store from '@/store'
import Attendance from '@/views/account/Attendance.vue'
import Menu from '@/views/account/Menu.vue'
import Rating from '@/views/account/Rating.vue'
import Settings from '@/views/account/Settings.vue';
import Notifications from '@/views/account/Notifications.vue';

const routes = [
  {
    path: '/',
    component: ViewMiddle,
    children: [
      {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
          requiredLogin: false
        }
      }
    ]
  },
  {
    path: '/account',
    component: ViewAccount,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiredLogin: true
        }
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
          requiredLogin: true
        }
      },
      {
        path: '/account/settings',
        name: 'settings',
        component: Settings,
        meta: {
          requiredLogin: true
        }
      },
      {
        path: '/account/reporting',
        name: 'reporting',
        component: Reporting,
        meta: {
          requiredLogin: true
        }
      },
      {
        path: '/account/attendance',
        name: 'attendance',
        component: Attendance,
        meta: {
          requiredLogin: true
        }
      },
      {
        path: '/account/menu',
        name: 'menu',
        component: Menu,
        meta: {
          requiredLogin: true
        }
      },
      {
        path: '/account/ratings',
        name: 'ratings',
        component: Rating,
        meta: {
          requiredLogin: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.afterEach(() => {
  document.activeElement.blur()

})

// Auth
router.beforeEach((to, from, next) => {
  const token = store.state.employee.sessionToken
  let forceLogout = false

  if (to.meta.requiredLogin && !token) {
    return next({ name: 'login' })
  } else {
    if (token) {
      if (!to.meta.requiredLogin) {
        return next({ name: 'dashboard' })
      }
      // check token
      // store.dispatch('auth/check').then((response) => {
      //   if (!response.success) {
      //     store.dispatch('auth/logout')
      //     next({ name: 'login' })
      //   }
      // }).catch(() => {
      //   store.dispatch('auth/logout')
      //   next({ name: 'login' })
      // })
    }


    // forceLogout
    if (forceLogout) {
      store.dispatch('employee/logout')
      next({ name: 'login' })
    } else next() // router next
  }
})

export default router
