<template>
  <div class="z-[999] toast" v-if="active" :class="classes">
    <div :class="snackbarClasses">
      <span>
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 3000
    },
    position: {
      type: String,
      default: 'center'
    },
    color: {
      type: String,
      default: 'info'
    }
  },
  emits: ['update:active'],
  data() {
    return {
      timer: null
    }
  },
  watch: {
    active() {
      this.setTimer()
    }
  },
  computed: {
    classes() {
      return `toast-${this.position}`
    },
    snackbarClasses() {
      return `alert ${this.color}`
    }
  },
  methods: {
    close() {
      this.$emit('update:active', false)
    },
    setTimer() {
      if (this.timer) clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.$emit('update:active', false)
      }, this.timeout)
    }
  }
}
</script>