<template>
  <view-container>
    <div class="flex justify-between flex-wrap gap-2 mb-6">
      <h3 class="font-bold">Nastavenia</h3>
    </div>

  </view-container>
</template>

<script>
export default {
  name: 'Settings'
}
</script>

<style scoped>

</style>
