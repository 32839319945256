export default {
  set(key, value, JsonStringify = false) {
    return localStorage.setItem(key, JsonStringify ? JSON.stringify(value) : value)
  },
  get(key, JsonParse = false) {
    return (JsonParse ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key)) || null
  },
  remove(key) {
    return localStorage.removeItem(key)
  },
}
