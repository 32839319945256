<template>
  <view-container :loading="loading">
    <div class="flex justify-between flex-wrap gap-2 mb-6">
      <h3 class="font-bold">Dochádzka</h3>

      <div class="flex gap-2 items-center">
        <div v-if="openAttendance" class="btn btn-neutral btn-sm cursor-default"
             :style="{ borderColor: openAttendance.type.color }">
          <div class="badge badge-primary badge-xs"
               :style="{ background: openAttendance.type.color, borderColor: openAttendance.type.color }"></div>
          {{ openAttendance.type.name }} {{ openAttendanceDuration }}
        </div>

        <button class="btn bg-primary btn-sm" @click="openAttendanceModal">
          Pridať dochádzku
        </button>

        <div class="join">
          <button class="btn btn-sm btn-neutral join-item" @click="goBackwards"> &lt;</button>
          <span class="btn btn-sm btn-neutral join-item">{{ formatDatetime(now, 'MMMM YYYY') }}</span>
          <button class="btn btn-sm btn-neutral join-item" @click="goForward"> ></button>
        </div>
      </div>
    </div>

    <div class="stats bg-neutral stats-vertical lg:stats-horizontal shadow mb-6 w-full">
      <div class="stat">
        <div class="stat-title">Práca</div>
        <div class="stat-value">{{ formatMinutes(summary.workTotalTotalPure) }}</div>
      </div>
      <div class="stat">
        <div class="stat-title">Homeoffice</div>
        <div class="stat-value">{{ formatMinutes(summary.homeofficeTotalPure) }}</div>
      </div>
      <div class="stat">
        <div class="stat-title">Obed</div>
        <div class="stat-value">{{ formatMinutes(summary.lunchTotalPure) }}</div>
      </div>
      <div class="stat">
        <div class="stat-title">Celkom odpracované</div>
        <div class="stat-value" :class="{
        'text-success': summary.specialWorkTotalPure / 60 >= monthSummary.workHours,
        }">{{ formatMinutes(summary.specialWorkTotalPure) }} / {{ monthSummary.workHours }}
        </div>
      </div>
      <div class="stat">
        <div class="stat-title">Nadčas</div>
        <div class="stat-value" :class="{
          'text-warning': summary.workDifferencePure < 0,
          'text-success': summary.workDifferencePure > 0,
        }">{{ formatMinutes(summary.workDifferencePure) }}
        </div>
      </div>
      <div class="stat">
        <div class="stat-title">Priemerný počet hodín v práci</div>
        <div class="stat-value">{{ monthSummary.averageDailyHours }}</div>
      </div>
    </div>

    <modal ref="modal" size="max-w-xs">
      <h3 class="font-bold mb-4">Vytvoriť dochádzku</h3>
      <div class="flex flex-col" v-if="attendanceTypesList">
        <button
          v-for="type in attendanceTypesList"
          :key="'type' + type.id"
          @click="selectAttendanceType(type.id)"
          :class="['btn mb-1',{ active: type.id == selectedAttendance }]"
        >
          <div class="badge badge-primary badge-xs"
               :style="{ background: type.color, borderColor: type.color }"></div>
          {{ type.name }}
        </button>
      </div>
      <template v-if="selectedAttendance && showButtons">
        <h3 class="font-bold my-4">{{ selectedAttendance.name }}</h3>
        <button class="btn btn-success w-full" v-if="!showEndButton" @click="startAttendance">Potvrdiť príchod
        </button>
        <button class="btn btn-error w-full" v-else @click="endAttendance">Potvrdiť odchod</button>
        <button class="btn base-100 w-full mt-2" @click="$refs.modal.close()">Zrušiť</button>
      </template>
      <div v-else-if="selectedAttendanceLoading" class="flex items-center justify-center">
        <Spinner/>
      </div>
    </modal>

    <div
      class="table"
    >
      <div class="row-header">
        <span>Deň</span>
        <span>Príchod</span>
        <span>Odchod</span>

        <span>Celkom</span>

        <span>Práca</span>
        <span>Homeoffice</span>
        <span>Obed</span>
        <span>Celkom odpr.</span>

        <span>Rozdiel</span>
        <span>Prerušenia</span>
      </div>
      <div
        class="row"
        v-for="(attendance, id) in attendances"
        :key="'attendance' + id"
      >
        <span data-title="Deň" class="date">
          <span class="badge mr-1" :class="{
            'badge-accent': attendance.isWeekend,
            'badge-info': attendance.isHoliday,
            'badge-primary': isToday(attendance.date),
          }">{{ formatDatetime(attendance.date, 'dd') }}</span>
          {{ formatDatetime(attendance.date, 'DD. MM. YYYY') }}
        </span>

        <template v-if="!attendance.disabledDay">
          <span data-title="Príchod">{{ attendance.attendanceStart }}</span>
          <span data-title="Odchod">{{ attendance.attendanceEnd }}</span>

          <span data-title="Celkom">{{ formatMinutes(attendance.attendanceTotalPure) }}</span>

          <span data-title="Práca">{{ formatMinutes(attendance.workTotalTotalPure) }}</span>
          <span data-title="Homeoffice">{{ formatMinutes(attendance.homeofficeTotalPure) }}</span>
          <span data-title="Obed">{{ formatMinutes(attendance.lunchTotalPure) }}</span>
          <span data-title="Celkom odpr.">{{ formatMinutes(attendance.specialWorkTotalPure) }}</span>

          <span data-title="Rozdiel">{{ formatMinutes(attendance.workDifferencePure) }}</span>
          <span data-title="Prerušenia">
            <div class="badge badge-lg border-none"
                 v-for="interruption in attendance.interruptions"
                 :key="interruption.id"
                 :title="interruption.type.name"
                 :style="{ background: interruption.type.color }">
              <b>{{ interruption.type.code }}:</b> {{ interruption.start_time }} - {{ interruption.end_time }}
            </div>
          </span>
        </template>
      </div>

      <div class="flex items-center gap-1 mt-6">
        Typy dní:
        <span class="badge badge-info mr-1">Sviatok</span>
        <span class="badge badge-accent mr-1">Víkend</span>
        <span class="badge badge-primary mr-1">Aktuálny deň</span>
      </div>
    </div>
  </view-container>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import { request } from '@/api/request'
import { orderBy, sumBy } from 'lodash'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'Attendance',
  components: { Spinner },
  data: () => ({
    loading: 1,
    now: null,

    attendances: null,
    monthSummary: null,

    selectedAttendance: null,
    selectedAttendanceLoading: false,

    showEndButton: false,
    showButtons: false,

    notEndedAttendanceExists: false,

    openAttendance: null,
    openAttendanceDuration: '00:00:00',
    timer: 0,

    attendanceTypesList: null
  }),
  computed: {
    ...mapState({
      employee: (state) => state.employee.employeeData
    }),
    summary() {
      return {
        attendanceTotalPure: sumBy(this.attendances, 'attendanceTotalPure'),
        workTotalTotalPure: sumBy(this.attendances, 'workTotalTotalPure'),
        homeofficeTotalPure: sumBy(this.attendances, 'homeofficeTotalPure'),
        workTotalPure: sumBy(this.attendances, 'workTotalPure'),
        lunchTotalPure: sumBy(this.attendances, 'lunchTotalPure'),
        specialWorkTotalPure: sumBy(this.attendances, 'specialWorkTotalPure'),
        workDifferencePure: sumBy(this.attendances, 'workDifferencePure')
      }
    }
  },
  watch: {
    now() {
      this.loadEmployeeAttendance()
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        const startDate = dayjs(this.openAttendance?.start_date || new Date());
        const now = dayjs();
        const diff = now.diff(startDate, 'seconds');
        this.openAttendanceDuration = this.formatSeconds(diff);
      }, 1000);
    },
    formatMinutes(min) {
      let negative = false
      if (min < 0) {
        min = Math.abs(min)
        negative = true
      }
      let hours = min / 60
      let rhours = Math.floor(hours)
      let minutes = (hours - rhours) * 60
      let rminutes = Math.round(minutes)
      return (
        (negative ? '-' : '') +
        rhours.toString().padStart(2, '0') +
        ':' +
        rminutes.toString().padStart(2, '0')
      )
    },
    formatSeconds(sec) {
      let hours = Math.floor(sec / 3600)
      let minutes = Math.floor((sec % 3600) / 60)
      let seconds = Math.floor((sec % 60))
      return (
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0')
      )
    },
    formatDatetime(date, format = 'D. M. YYYY HH:mm', locale = 'sk') {
      if (!date) {
        return
      }
      return dayjs(date).locale(locale).format(format)
    },
    async loadEmployeeAttendance() {
      this.loading = 1
      const data = await request('getEmployeeAttendance',
        {
          'month': this.now.format('M'),
          'year': this.now.format('YYYY')
        })

      if (data && data.success) {
        this.monthSummary = data.monthSummary
        this.attendances = orderBy(data.attendances)
      }
      this.loading = 0
    },
    goBackwards() {
      this.now = dayjs(this.now).subtract(1, 'months')
    },
    goForward() {
      this.now = dayjs(this.now).add(1, 'months')
    },
    isToday(date) {
      if (dayjs(date).isSame(dayjs(), 'day')) {
        return true
      }
      return false
    },
    openAttendanceModal() {
      this.selectedAttendance = null
      this.$refs.modal.open()
    },
    async loadAttendanceTypes() {
      this.attendanceTypesList = await request('getAttendanceTypes')
    },
    async selectAttendanceType(id) {
      this.selectedAttendanceLoading = true
      this.selectedAttendance = this.attendanceTypesList.find(t => t.id === id)
      this.showButtons = false
      const data = await request('verifyAttendace', {
        typeId: this.selectedAttendance.id
      })
      this.notEndedAttendanceExists = data.exists
      this.showButtons = true
      this.showEndButton = data.exists
      this.selectedAttendanceLoading = false
    },
    async startAttendance() {
      const data = await request('saveStartAttendance', {
        typeId: this.selectedAttendance.id
      })

      this.message = data.message
      this.success = data.success
      if (data && data.success) {
        this.$store.dispatch('error/handler', {
          message: 'Príchod bol zaznamenaný. ' +
            (data.notEndedAttendanceExists
              ? 'Predchodzá dochádzka bola automaticky ukončená.'
              : ''),
          color: 'alert-success'
        })
      }
      this.selectedAttendance = null
      this.getOpenAttendance()
    },
    async endAttendance() {
      const data = await request('saveEndAttendance', {
        typeId: this.selectedAttendance.id
      })
      this.success = data.success
      if (data && data.success) {
        this.$store.dispatch('error/handler', {
          message: 'Odchod bol zaznamenaný.',
          color: 'alert-success'
        })
      }
      this.selectedAttendance = null
      await this.getOpenAttendance()
      this.clearTimer()
    },
    async getOpenAttendance() {
      this.openAttendance = null

      const data = await request('getOpenAttendance')
      if (data && data.success) {
        this.openAttendance = data.attendance
        this.startTimer()
      }
    },

    clearTimer() {
      this.openAttendance = null
      clearInterval(this.timer)
    }
  },
  async created() {
    this.loading = 1
    this.now = dayjs()
    await this.loadAttendanceTypes()
    await this.loadEmployeeAttendance()
    await this.getOpenAttendance()
    this.loading = 0
  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  color: #f5f5f5;

  .row-header {
    @apply bg-neutral;
    border-radius: 6px;
    font-size: 11px;
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr;
    align-items: center;
    padding: 8px 16px;

    @media only screen and (max-width: 650px) {
      display: none;
    }

    span {
      display: block;
      padding: 4px;
      text-align: center;

      &:not(:last-child) {
        border-right: 1px solid #444;
      }
    }
  }

  .row {
    @apply bg-neutral;
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 2fr;
    align-items: center;
    margin: 12px 0px;
    min-height: 60px;
    border-radius: 6px;
    padding: 8px 16px;

    @media only screen and (max-width: 650px) {
      grid-template-columns: 1fr;
    }

    &:nth-child(odd) {
      @apply bg-opacity-40;
    }

    & > span {
      display: block;
      padding: 8px;
      text-align: center;

      &.date {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .day-name {
          font-size: 11px;
          color: #999;
          margin-right: 8px;
        }

        @media only screen and (max-width: 650px) {
          display: block;
          align-items: flex-start;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid #444;
      }

      @media only screen and (max-width: 650px) {
        text-align: left;
        &:not(:last-child) {
          border-right: 0px solid #444;
        }
        &:before {
          content: attr(data-title);
          font-size: 11px;
          color: #999;
          display: block;
          margin-bottom: 6px;
        }
      }
    }
  }
}
</style>
